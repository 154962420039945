import { createRouter, createWebHistory } from 'vue-router'
import Cookie from 'cookie-universal'
const cookie = Cookie()
if (!cookie.get('lang')) {
  cookie.set('lang', 'ar');
}
import meta_Description from './Description.json'
import meta_keywords from './Keywords.json'
const routes = [
  {
    path: '/',
    name: cookie.get('lang') == 'ar' ? 'الصفحة الرئيسية' : 'Home Page',
    component: () => import('../views/Client/Home.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الرئيسية' : 'Etmaam - Home Page',
      metaTags: [
        {
          name: 'description',
          content: meta_Description.Home_Description
        },
        {
          name: 'keywords',
          content: meta_keywords.Home_Keywords
        }
      ]
    },
  },
  // ******************************* Client **********************************
  // ___________________ about  ___________________
  {
    path: '/about',
    name: cookie.get('lang') == 'ar' ? 'من نحن' : 'About Us',
    component: () => import('../views/Client/About.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - نبذة عنا' : 'Etmaam - About Us',
    }
  },
  //___________________ bank accounts  ___________________
  {
    path: '/banks',
    name: cookie.get('lang') == 'ar' ? 'حساباتنا البنكية' : 'Bank accounts',
    component: () => import('../views/Client/BankAccounts.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - حساباتنا البنكية' : 'Etmaam - Bank accounts',
    }
  },
  // ___________________ contact  ___________________
  {
    path: '/contact',
    name: cookie.get('lang') == 'ar' ? 'اتصل بنا' : 'Contact Us',
    component: () => import('../views/Client/Contact.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - اتصل بنا' : 'Etmaam - Contact US',
    }
  },
  // ___________________ services  ___________________
  {
    path: '/services',
    name: cookie.get('lang') == 'ar' ? 'الخدمات' : 'Services',
    component: () => import('../views/Client/Services/Services.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات' : 'Etmaam - Services',
    },
  },
  {
    path: '/services/company-formation',
    name: cookie.get('lang') == 'ar' ? 'تأسيس الشركات' : 'Company Formation',
    component: () => import('../views/Client/Services/StaticPages/CompanyFormationPage.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تأسيس الشركات' : 'Etmaam - Company Formation',
    },
  },
  {
    path: '/services/legal-services',
    name: cookie.get('lang') == 'ar' ? 'الخدمات القانونية' : 'Legal Services',
    component: () => import('../views/Client/Services/StaticPages/LegalServices.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات القانونية' : 'Etmaam - Legal Services',
    },
  },
  {
    path: '/services/electronic-services',
    name: cookie.get('lang') == 'ar' ? 'إدارة الخدمات الإلكترونية' : 'Electronic Services',
    component: () => import('../views/Client/Services/StaticPages/ElectronicServices.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إدارة الخدمات الإلكترونية' : 'Etmaam - Electronic Services',
    },
  },
  {
    path: '/services/foreign-services',
    name: cookie.get('lang') == 'ar' ? 'خدمات الاستثمار الاجنبي' : 'Foreign investment services',
    component: () => import('../views/Client/Services/StaticPages/ForeignServices.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - خدمات الاستثمار الاجنبي' : 'Etmaam - Foreign investment services',
    },
  },
  {
    path: '/services/wage-protection',
    name: cookie.get('lang') == 'ar' ? 'حماية الاجور' : 'Wage Protection',
    component: () => import('../views/Client/Services/StaticPages/WageProtection.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - حماية الاجور' : 'Etmaam - Wage Protection',
    },
  },
  {
    path: '/services/trademark-registration',
    name: cookie.get('lang') == 'ar' ? 'تسجيل العلامات التجارية' : 'Trademark Registration',
    component: () => import('../views/Client/Services/StaticPages/TrademarkRegistration.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تسجيل العلامات التجارية' : 'Etmaam - Trademark Registration',
    },
  },

  {
    path: '/services/details/:id',
    name: cookie.get('lang') == 'ar' ? 'تفاصيل الخدمة' : 'Service Details',
    component: () => import('../views/Client/ServiceDetails.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات' : 'Etmaam - Services',
    }
  },
  {
    path: '/services/order/:id',
    name: cookie.get('lang') == 'ar' ? 'طلب خدمة' : 'Service Order',
    component: () => import('../views/Client/Order/Service/OrderService.vue'),
    meta: {
      requiresAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - طلب خدمة' : 'Etmaam - Service Order',
    }
  },
  {
    path: '/services/order/details/:id',
    name: cookie.get('lang') == 'ar' ? 'الخدمات - تفاصيل الطلب' : 'Services - Order Details',
    component: () => import('../views/Client/Order/Service/OrderDetails.vue'),
    meta: {
      requiresAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات - تفاصيل الطلب' : 'Etmaam - Services - Order Details',
    }
  },
  {
    path: '/services/order/:id/review',
    name: cookie.get('lang') == 'ar' ? 'الخدمات - تقييم الخدمة' : 'Services - Review',
    component: () => import('../views/Client/Order/Service/ReviewPage.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات - تقييم الخدمة' : 'Etmaam - Services - Review',
    }
  },
  {
    path: '/services/order/:id/price-offer',
    name: cookie.get('lang') == 'ar' ? 'الخدمات - عرض سعر الخدمة' : 'Services -PriceOffer',
    component: () => import('../views/Client/Order/Service/price-offer.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات - عرض سعر الخدمة' : 'Etmaam - Services -PriceOffer',
    }
  },
  {
    path: '/services/order/:id/payment',
    name: cookie.get('lang') == 'ar' ? 'الخدمات - الدفع لطلب الخدمة' : 'Services -Payment',
    component: () => import('../views/Client/Order/Service/payment.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات - الدفع لطلب الخدمة' : 'Etmaam - Services -payment',
    }
  },
  // ___________________ packages  ___________________
  {
    path: '/packages',
    name: cookie.get('lang') == 'ar' ? 'الباقات' : 'Packages',
    component: () => import('../views/Client/Packages.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الباقات' : 'Etmaam - Packages',
    }
  },
  {
    path: '/packages/order/:id',
    name: cookie.get('lang') == 'ar' ? 'طلب باقة' : 'Packages Order',
    component: () => import('../views/Client/Order/Package/OrderPackage.vue'),
    meta: {
      requiresAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - طلب باقة' : 'Etmaam - Packages Order',
    }
  },
  {
    path: '/packages/order/details/:id',
    name: cookie.get('lang') == 'ar' ? 'الباقات - تفاصيل الطلب' : 'Packages - Order Details',
    component: () => import('../views/Client/Order/Package/OrderDetails.vue'),
    meta: {
      requiresAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الباقات - تفاصيل الطلب' : 'Etmaam - Packages - Order Details',
    }
  },
  //____________________ CommercialFranchise ________________

  {
    path: '/commercial-franchise',
    name: cookie.get('lang') == 'ar' ? 'الامتياز التجاري' : 'Commercial Franchise',
    component: () => import('../views/Client/CommercialFranchise.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الامتياز التجاري' : 'Etmaam - Commercial Franchise',
    }
  },
  // ___________________ business-setup___________________
  // {
  //   path: '/business-setup',
  //   name: cookie.get('lang') == 'ar' ? 'تأسيس الشركات' : 'Business Setup',
  //   component: () => import('../views/Client/BusinessSetup.vue'),
  //   meta: {
  //     title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تأسيس الشركات' : 'Etmaam - Business Setup' ,
  //   }
  // },
  {
    path: '/faqs',
    name: cookie.get('lang') == 'ar' ? 'الأسئلة الشائعة' : 'FAQs',
    component: () => import('../views/Client/FAQs.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الأسئلة الشائعة' : 'Etmaam - FAQs',
    }
  },
  {
    path: '/blogs',
    name: cookie.get('lang') == 'ar' ? 'المدونة' : 'Blogs',
    component: () => import('../views/Client/blogs/blogs.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - المدونة' : 'Etmaam - Blogs',
    }
  },
  {
    path: '/blogs/blogs-details/:id',
    name: cookie.get('lang') == 'ar' ? 'تفاصيل المدونة' : 'Blogs Details',
    component: () => import('../views/Client/blogs/blogsDetials.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تفاصيل المدونة' : 'Etmaam - Blogs Details',
    }
  },
  {
    path: '/privacy-policy',
    name: cookie.get('lang') == 'ar' ? 'سياسة الخصوصية' : 'Privacy Policy',
    component: () => import('../views/Client/PrivacyPolicy.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - سياسة الخصوصية' : 'Etmaam - Privacy Policy',
    }
  },
  {
    path: '/using-policy',
    name: cookie.get('lang') == 'ar' ? 'الشروط و الاستخدام' : 'Using Policy',
    component: () => import('../views/Client/UsingPolicy.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الشروط و الاستخدام' : 'Etmaam - Using Policy',
    }
  },
  {
    path: '/jobs',
    name: cookie.get('lang') == 'ar' ? 'الوظائف' : 'Jobs',
    component: () => import('../views/Client/Jobs.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الوظائف' : 'Etmaam - Jobs',
    }
  },
  {
    path: '/jobs/details/:id',
    name: cookie.get('lang') == 'ar' ? 'تفاصيل العمل' : 'Job details',
    component: () => import('../views/Client/JobsDetails.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تفاصيل الوظيفة' : 'Etmaam - Job Details',
    }
  },
  {
    path: '/downloads-center',
    name: cookie.get('lang') == 'ar' ? 'مركز التحميلات' : 'Downloads Center',
    component: () => import('../views/Client/DownloadsCenter.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - مركز التحميلات' : 'Etmaam - Downloads Center',
    }
  },
  {
    path: '/join-us',
    name: cookie.get('lang') == 'ar' ? 'انضم إلينا' : 'Join Us',
    component: () => import('../views/Client/JoinUs.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - انضم إلينا' : 'Etmaam - Join Us',
    }
  },
  // ******************************* Profile  *******************************
  {
    path: '/profile/home',
    name: cookie.get('lang') == 'ar' ? 'لوحة التحكم الرئيسية' : 'Home Dashboard',
    component: () => import('../views/Profile/DashboardHome.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - لوحة التحكم الرئيسية' : 'Etmaam - Home Dashboard',
    }
  },
  {
    path: '/profile/notifications',
    name: cookie.get('lang') == 'ar' ? 'الإشعارات' : 'Notifications',
    component: () => import('../views/Profile/Notifications.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الإشعارات' : 'Etmaam - Notifications',
    }
  },
  {
    path: '/profile/my-account',
    name: cookie.get('lang') == 'ar' ? 'حسابي الشخصي' : 'My Account',
    component: () => import('../views/Profile/MyAccount.vue'),
    meta: {
      // requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الحساب الشخصي' : 'Etmaam - My Account',
    }
  },
  {
    path: '/profile/required-packages',
    name: cookie.get('lang') == 'ar' ? 'الباقات المطلوبة' : 'Requested Packages',
    component: () => import('../views/Profile/Required_packages.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الباقات المطلوبة' : 'Etmaam - Requested Packages',
    }
  },
  {
    path: '/profile/subscriptions',
    name: cookie.get('lang') == 'ar' ? 'إدارة الاشتراكات' : 'Subscriptions',
    component: () => import('../views/Profile/Subscriptions.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إدارة الاشتراكات' : 'Etmaam - Subscriptions',
    }
  },
  {
    path: '/profile/individialRequests',
    name: cookie.get('lang') == 'ar' ? 'الطلبات الفردية' : 'Individial Requests',
    component: () => import('../views/Profile/IndividialRequests.vue'),
    meta: {
      // requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الطلبات الفردية' : 'Etmaam - Individial Requests',
    }
  },
  {
    path: '/profile/required-services',
    name: cookie.get('lang') == 'ar' ? 'الخدمات المطلوبة' : 'Requested Services',
    component: () => import('../views/Profile/Required_services.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الخدمات المطلوبة' : 'Etmaam - Requested Services',
    }
  },
  {
    path: '/profile/documents',
    name: cookie.get('lang') == 'ar' ? 'الوثائق ' : 'Documents',
    component: () => import('../views/Profile/Documents.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الوثائق ' : 'Etmaam - Documents',
    }
  },
  {
    path: '/profile/consulting',
    name: cookie.get('lang') == 'ar' ? 'الاستشارات ' : 'Consulting',
    component: () => import('../views/Profile/Consulting.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - الاستشارات ' : 'Etmaam - Consulting',
    }
  },
  {
    path: '/profile/companies',
    name: cookie.get('lang') == 'ar' ? 'منشآتي ' : 'Companies',
    component: () => import('../views/Profile/Companies/Companies.vue'),
    meta: {
      // requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - منشآتي ' : 'Etmaam - Companies',
    }
  },
  {
    path: '/profile/company/add',
    name: cookie.get('lang') == 'ar' ? 'إضافة منشأة ' : 'Add Company',
    component: () => import('../views/Profile/Companies/AddCompany.vue'),
    meta: {
      // requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إضافة منشأة ' : 'Etmaam - Add Company',
    }
  },
  {
    path: '/profile/company/licence',
    name: cookie.get('lang') == 'ar' ? 'شهادات المنشأة' : 'Company Licence',
    component: () => import('../views/Profile/Companies/CompanyLicence.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - شهادات المنشأة' : 'Etmaam - Company Licence',
    }
  },

  {
    path: '/profile/companies/managing-personnel',
    name: cookie.get('lang') == 'ar' ? 'إدارة افراد المنشأة' : 'Employees Management',
    component: () => import('../views/Profile/Companies/ManagingPersonnel.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إدارة افراد المنشأة' : 'Etmaam - Employees Management',
    }
  },
  {
    path: '/profile/companies/managing-roles',
    name: cookie.get('lang') == 'ar' ? 'إدارة الأدوار و الصلاحيات' : 'Managing Roles and Powers',
    component: () => import('../views/Profile/Companies/ManagingRoles.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إدارة الأدوار و الصلاحيات' : 'Etmaam - Managing Roles and Powers',
    }
  },
  {
    path: '/profile/support',
    name: cookie.get('lang') == 'ar' ? 'مركز الدعم ' : 'Support Center',
    component: () => import('../views/Profile/Support.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - مركز الدعم ' : 'Etmaam - Support Center',
    }
  },
  {
    path: '/profile/ticket/details/:id',
    name: cookie.get('lang') == 'ar' ? 'تفاصيل التذكرة' : 'Ticket Details',
    component: () => import('../views/Profile/TicketDetails.vue'),
    meta: {
      requiresCompanyAuth: true,
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تفاصيل التذكرة ' : 'Etmaam - Ticket Details',
    }
  },


  // ___________________ Login ___________________

  {
    path: '/accept-invite',
    name: cookie.get('lang') == 'ar' ? 'قبول دعوة' : 'Accept Invite',
    component: () => import('../views/accept-invite.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - قبول دعوة' : 'Etmaam - Accept Invite',
    },
  },
  {
    path: '/login',
    name: cookie.get('lang') == 'ar' ? 'تسجيل الدخول' : 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تسجيل الدخول' : 'Etmaam - Login',
      requiresHome: true
    }
  },
  {
    path: '/register',
    name: cookie.get('lang') == 'ar' ? 'إنشاء حساب' : 'Register',
    component: () => import('../views/auth/Register.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - إنشاء حساب' : 'Etmaam - Register',
      requiresHome: true,

    }
  },
  {
    path: '/forgot',
    name: cookie.get('lang') == 'ar' ? 'نسيت كلمة المرور' : 'Forgot Password',
    component: () => import('../views/auth/Forgot.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - نسيت كلمة المرور' : 'Etmaam - Forgot Password',
      requiresHome: true,
    }
  },
  {
    path: '/reset-password',
    name: cookie.get('lang') == 'ar' ? 'تغيير كلمة المرور' : 'Reset Password',
    component: () => import('../views/auth/ResetPassword.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تغيير كلمة المرور' : 'Etmaam - Reset Password',
    }
  },
  {
    path: '/verification',
    name: cookie.get('lang') == 'ar' ? 'تأكيد الحساب' : 'Verification',
    component: () => import('../views/auth/Verification.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تأكيد الحساب' : 'Etmaam - Verification',
    }
  },
  {
    path: '/company-login',
    name: cookie.get('lang') == 'ar' ? ' تسجيل الدخول بشركة' : 'Login a company',
    component: () => import('../views/auth/ProfileLogin.vue'),
    meta: {
      title: cookie.get('lang') == 'ar' ? 'اتمام للخدمات - تسجيل الدخول بشركة' : 'Etmaam - Login a Company',
      requiresAuth: true,
      LoginCompany: true,
    }
  },
  // ___________________ 404 page  ___________________
  {
    path: '/fix',
    name: cookie.get('lang') == 'ar' ? 'الموقع فيد الصيانة و التطوير' : 'The site is useful for maintenance and development',
    component: () => import('../views/Fix.vue')
  },
  {
    path: '/thanks',
    name: cookie.get('lang') == 'ar' ? 'شكرا لكم' : 'Thanks For You',
    component: () => import('../views/Thanks.vue')
  },
  {
    path: '/thanks-contact',
    name: cookie.get('lang') == 'ar' ? 'شكرا لتواصلكم' : 'Thanks For Contact',
    component: () => import('../views/ThanksContact.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error Page',
    component: () => import('../views/Error.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//  for meta title
import store from '../store';

router.beforeResolve((to, from) => {
  store.commit('RESET_ORDER');
  document.title = to.meta.title || "إتمام"
  if (to.meta.hasOwnProperty('metaTags')) {
    for (let x = 0; x < to.meta.metaTags.length; x++) {
      let oldMeta = document.getElementsByTagName('meta');
      for (let m = 0; m < oldMeta.length; m++) {
        if (oldMeta[m].name == to.meta.metaTags[x].name) {
          oldMeta[m].remove()
        }
      }
      let meta = document.createElement('meta')
      if (to.meta.metaTags[x] && to.meta.metaTags[x].hasOwnProperty('name')) {
        meta.name = to.meta.metaTags[x].name
        meta.content = to.meta.metaTags[x].content
      }
      if (to.meta.metaTags[x].hasOwnProperty('property')) {
        meta.property = to.meta.metaTags[x].name
        meta.content = to.meta.metaTags[x].content
      }
      document.head.appendChild(meta)
    }
  }
  if (to.meta.requiresAuth && cookie.get('Token') == undefined) {
    store.state.VerfyLogin = true
  }
  if (to.meta.requiresHome && cookie.get('Token')) {
    return {
      path: '/',
    }
  }
  if (to.meta.requiresCompanyAuth && cookie.get('CompanyToken') == undefined) {
    return {
      path: '/company-login',
    }
  }
  // if (to.meta.LoginCompany && cookie.get('CompanyToken') != undefined) {
  //   return {
  //     path: '/profile/home',
  //   }
  // }
})
export default router
