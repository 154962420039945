<template>
  <Loading v-if="$store.state.loader" />
  <notifications
    :max="1"
    :class="[this.$i18n.locale == 'ar' ? 'ar' : 'en']"
    position="bottom right"
  />
  <main
    id="back"
    :class="[
      this.$i18n.locale == 'ar' ? 'ar-dir' : 'en-dir',
      switchHeader ? 'light' : '',
    ]"
  >
    <div id="main-page">
      <template v-if="!isErrorRoute">
        <Header v-if="!switchHeader" />
        <HeaderProfile v-else />
      </template>

      <!-- Use a custom transition or fallback to `fade` -->
      <Transition name="fade-page" mode="out-in">
        <div>
          <router-view />
        </div>
      </Transition>
      <template v-if="!isErrorRoute">
        <Footer
          v-if="!switchHeader"
          v-show="
            !$route.path.includes('login') || !$route.path.includes('register')
          "
        />
      </template>
    </div>
    <div
      id="report"
      @click="OpenModal"
      class="itemm"
      :class="[
        'd-flex flex-column',
        'guide',
        { animate__animated: isHovered1, animate__pulse: isHovered1 },
        $i18n.locale === 'ar' ? 'guide-ar' : 'guide-en',
      ]"
      @mouseenter="handleMouseEnter1"
      @mouseleave="handleMouseLeave1"
    >
      <span>{{ $t("report_a_problem") }}</span>
      <img
        src="@/assets/img/global/error.png"
        height="40px"
        width="54px"
        alt="report a problem"
      />
    </div>
    <div v-if="ShowReprot" @click="OpenModal" class="overlay open"></div>
    <div v-if="ShowReprot" id="reprot-modal">
      <div class="close" @click="closeModal">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
            fill="#052C57"
          />
        </svg>
      </div>
      <div class="mb-3" v-show="screenshot == ''">
        <p class="mt-2 top-title">{{ $t("Report a problem") }}</p>

        <input
          class="uplo-file"
          type="file"
          @change="onFileChange"
          accept="image/*"
        />
      </div>
      <div class="mb-3 text-center" v-show="screenshot != ''">
        <img :src="screenshot" alt="Screenshot" class="screenshot-image" />
      </div>

      <div class="mb-3">
        <input
          type="text"
          v-model="description"
          :placeholder="$t('Report Description')"
        />
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <button class="btn-third mx-2 me-2" @click="closeModal">
          {{ $t("Close") }}
        </button>
        <button class="btn-main" @click="sendReport">{{ $t("Send") }}</button>
      </div>
    </div>
  </main>
<!--  <Notification />-->
</template>
<script>
// @ is an alias to /src
import Cookie from 'cookie-universal'
import { getToken, onMessage } from 'firebase/messaging' // For Firebase Cloud Messaging (FCM)
import { messaging } from '@/firebase'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { defineAsyncComponent } from 'vue'

const cookie = Cookie()
export default {
  name: 'app',
  data () {
    return {
      ShowReprot: false,
      description: '',
      screenshot: '',
      imageFile: [],
      showPrompt: false,
      isLoggedIn: !!cookie.get("Userdata"), // Initialize based on cookie
    }
  },
  computed: {
    isErrorRoute () {
      return this.$route.name === 'Error Page' // Adjust this if necessary
    },
    switchHeader () {
      if (this.$route.path.includes('profile')) {
        return true
      } else {
        return false
      }
    },
    isLoggedIn () {
      return !!cookie.get('Userdata') // Returns false if Userdata is null/undefined
    },
  },
  components: {
    Loading: defineAsyncComponent(() =>
      import('@/components/Global/Loading.vue')
    ),
    Header: defineAsyncComponent(() =>
      import('@/components/Common/Client/Header.vue')
    ),
    HeaderProfile: defineAsyncComponent(() =>
      import('@/components/Common/Profile/HeaderProfile.vue')
    ),
    Footer: defineAsyncComponent(() =>
      import('@/components/Common/Client/Footer.vue')
    ),
    // Notification: defineAsyncComponent(() =>
    //   import("@/components/Notifications/NotificationComponent.vue")
    // ),
  },
  watch: {
    $route (to, from) {
      $('html, body').animate({ scrollTop: 0 }, 500)
    },
    isLoggedIn (newValue) {
      if (newValue) {
        this.checkNotificationStatus()
      }
    }
  },
  methods: {
    async declinePermission () {
      this.showPrompt = false
      Cookie().set('notificationAsked', true)
      this.$store.commit('SET_FCMTOKEN', null)
    },
    async requestNotificationPermission () {
      try {

        const permission = await Notification.requestPermission()
        this.showPrompt = false
        if (permission === 'granted') {
          cookie.set('notificationAsked', false)
          const token = await getToken(messaging, { vapidKey: "BKFdBcZLsvy8GqypQJcVx81LsBHVVn5tQIPYw1SlN4N3CqAg4-K0Q5jTlGcT63yV7Fi7g9FRrbewVyXjEjwdBdE" });
          cookie.set('fcmToken', token)
          this.$store.commit('SET_FCMTOKEN', token)
          await axios.post(
            'user/change-fcm-token',
            { fcm_token: token },
            {
              headers: { Authorization: `Bearer ${cookie.get("Token")}` },
            }
          )
        } else {
          cookie.set('notificationAsked', true)
          this.$store.commit('SET_FCMTOKEN', null)
          cookie.set('fcmToken', null)
        }
      } catch (error) {
        console.error('Error getting FCM token:', error)
      }
    },
    setupNotificationListener () {
      onMessage(messaging, (payload) => {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon,
        })
      })
    },
    async checkNotificationStatus() {
      if (!this.isLoggedIn) return; // Don't ask if user is not logged in

      let notificationAsked = cookie.get("notificationAsked");

      if (notificationAsked === undefined) {
        cookie.set("notificationAsked", "false");
        notificationAsked = "false";
      }

      if (Notification.permission === "default") {
        await this.requestNotificationPermission();
      } else if (Notification.permission === "granted") {
        await this.requestNotificationPermission(); // Request token & send to backend
      } else {
      }
    },
    handleMouseEnter1 () {
      this.isHovered1 = true
    },
    handleMouseLeave1 () {
      this.isHovered1 = false
    },
    onFileChange (event) {
      const file = event.target.files[0] // Get the uploaded file
      this.imageFile = file
      if (file) {
        const reader = new FileReader() // Create a FileReader to read the file
        reader.onload = (e) => {
          this.screenshot = e.target.result // Set the image source to the result
        }
        reader.readAsDataURL(file) // Read the file as a data URL
      }
    },
    OpenModal () {
      if (!cookie.get('Token')) {
        this.$router.push('/login')
      } else {
        this.ShowReprot = true
      }
    },
    closeModal () {
      this.ShowReprot = false
    },
    sendReport () {
      var slef = this
      if (slef.imageSrc == '' || slef.description == '') {
        if (slef.imageSrc == '') {
          notify({
            type: 'error',
            text: 'عليك رفع صورة',
          })
        }
        if (slef.description == '') {
          notify({
            type: 'error',
            text: 'عليك كتابة وصف للمشكلة',
          })
        }
      } else {
        const bodyData = new FormData()
        bodyData.append('status', 'open')
        bodyData.append('image', slef.imageFile)
        bodyData.append('description', slef.description)
        axios
          .post('/user/problem-reports', bodyData, {
            headers: { Authorization: `Bearer ${cookie.get('Token')}` },
          })
          .then((res) => {
            if (res.status == 200) {
              slef.imageFile = []
              slef.screenshot = ''
              slef.description = ''
              slef.closeModal()
              notify({
                type: 'success',
                text: 'تم ارسال المشكلة لفريق تقنية المعلومات سنعلمكم بأحدث التطورات',
              })
            }
          })
          .catch(function (error) {
            console.log('Error: ', error)
            slef.imageSrc = ''
            slef.description = ''
            slef.closeModal()
            notify({
              type: 'error',
              text: error?.response?.data?.message,
            })
          })
      }
    },
    syncLoginState() {
      const userData = cookie.get("Userdata");
      if (userData) {
        this.isLoggedIn = true;
        this.checkNotificationStatus();
      } else {
        this.isLoggedIn = false;
      }
    },
  },
  mounted () {
    // Back to Top Button Setup
    const backToTopButton = document.createElement('button')
    backToTopButton.textContent = 'Back to Top'
    backToTopButton.classList.add('back-to-home')
    backToTopButton.innerHTML = `
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.24023" width="35" height="35" rx="4.5" stroke="transparent"/>
      <path d="M13.6796 18.0331L17.9293 13.7401L16.9395 12.7402L11 18.7402L11.4949 19.2402L16.9395 24.7402L17.9293 23.7404L13.6796 19.4474H25V18.0331H13.6796Z" fill="white"/>
    </svg>
  `
    document.getElementById('back').appendChild(backToTopButton)

    backToTopButton.addEventListener('click', function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })

    window.addEventListener('scroll', function () {
      const middleOfScreen = window.innerHeight * 0.7
      const scrollPosition = window.scrollY

      if (scrollPosition > middleOfScreen) {
        backToTopButton.classList.add('show')
      } else {
        backToTopButton.classList.remove('show')
      }
    })


    this.setupNotificationListener();

    if (this.isLoggedIn) {
      this.checkNotificationStatus();
    }

    // Ensure this runs when user logs in
    window.addEventListener("storage", this.syncLoginState);
  },
  beforeUnmount () {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('keydown', this.handleGlobalKeyDown)
    window.removeEventListener("storage", this.syncLoginState)
  },
}
</script>

<style lang="scss">
.fade-page-enter-active,
.fade-page-leave-active {
  transition: 0.6s ease;
}

.fade-page-enter-from,
.fade-page-leave-to {
  transform: translateY(-100px);
}
body {
  height: 100vh;
}
.ar-dir {
  direction: rtl;

  #nav {
    right: 0;
  }
}

.en-dir {
  #nav {
    left: 0;
  }
}

#app {
  font-family: "Tajawal", sans-serif;
  font-display: swap;
}

.top-title {
  text-align: center;
  color: #052c57;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
}

//
//.itemm {
//  transition: transform 0.3s ease;
//  &:hover {
//    transform: scale(1.1);
//  }
//}
</style>
